body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #000000;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #d53823, #d58223);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

#content {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#header {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}

#footer {
  display: flex;
  justify-content: center;
  margin: 1rem;
  margin-bottom: 5rem;
}

#footer button {
  margin: .5rem;
}

#hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#hero-text {
  padding: 5rem;
}

h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: 15rem;
  margin: 0;
  line-height: 1;
  text-shadow: 5px 5px #ffffff;
}

@media (max-width:1700px) {
  h1 {
    font-size: 10rem;
  }
  #hero img {
    width: 400px;
  }
}

@media (max-width:1200px) {
  h1 {
    font-size: 8rem;
    text-shadow: 3px 3px #ffffff
  }
  #hero img {
    width: 250px;
  }
}

@media (max-width:650px) {
  h1 {
    font-size: 5rem;
    text-shadow: 2px 2px #ffffff;
  }
  #hero-text {
    text-align: center;
    padding: 2rem;
  }
}

#hero img {
  border-radius: 20px;
  box-shadow: 10px 10px #ffffff;
  max-width: 700px;
}

#hero img:hover {
  animation: shake 0.25s;
}

a {
  color: #ffffff;
}

a:hover {
  color: #3c1515;
}

a .btn {
  color: #3c1515;
}

a .btn:hover {
  color: #d52323;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}